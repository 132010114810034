<template>
  <base-section
    id="claim"
    space="50">
    <v-row>
    <v-col
    cols = "12"
    md   = "8"
    >
    <v-container>
      <base-section-heading title="Apakah Anda Membutuhkan Bantuan Klaim Asuransi?" align="left"/>
      <v-slide-group>
        <template v-for="(data, index) in posts">
          <v-slide-item
            :key="data.web_product_id"
            class="align-self-center"
          >
          	<div class="text-center">
            <base-img
              :src="data.web_product_src_url"
              color="grey"
              height="150"
              width="180"
              class="cursor"
              :alt="data.web_product_name"
            />
            {{ data.web_product_name }}
        	</div>
          </v-slide-item>

          <v-responsive
            v-if="index < posts.length"
            :key="`divider-${index}`"
            class="text-center"
            height="150"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
	</v-col>
	<v-col
    cols = "12"
    md   = "4"
    >
    <v-container>
    	<v-card
    	max-width ="344">
    		<v-card-text class="body-1">
    		  <span class="body-2">Punya pertanyaan tentang <b>Klaim Asuransi</b>? Pakar Bantuan Klaim kami hanya berjarak satu Chat WhatsApp.</span>
    		  <div>
    		  	<v-btn color="secondary" outlined block large class="title" @click="Whatsapp"><v-icon>mdi-whatsapp</v-icon>0812 9321 9318</v-btn>
    		  </div>
    		</v-card-text>
    	</v-card>
    </v-container>
	</v-col>
	</v-row>
	<v-snackbar
    v-model="snackbar.value"
  	>
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
    <v-btn
      color="fourth"
      text
      v-bind="attrs"
      @click="snackbar.value = false"
    >
      Close
    </v-btn>
  </template>
  </v-snackbar>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionClaim',
    data: () => ({
      loading: false,
      posts: [],
      snackbar: {
        value: false,
        text: ''
      }
    }),
    created () {
      let checkproduct = JSON.parse(localStorage.getItem('web_atu_product'))
      if (checkproduct !== null) {
        this.posts = checkproduct
      } else {
        this.List()
      }
    },
    methods: {
      URL (url) {
        window.open(url)
      },
      List () {
        let limits = 6 
        this.loading  = true
        let formdata = {
              web_product_actived: 'Y',
              limit: limits,
              order_by: 'web_product_sort',
              order_type: 'ASC',
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebProductList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let data = response.data
            if (data.length > 0) {
              if (data[0].feedback === 'Y') {
                this.posts = data
                localStorage.setItem('web_atu_product', JSON.stringify(data))
              } else {
                this.snackbar.value = true
                this.snackbar.text  = data[0].feedback
              }
            } else {
              this.posts = []
            }
            this.loading  = false
        })
        .catch(e => {
            this.snackbar.value = true
            this.snackbar.text  = e
            this.loading  = false
        })
      },
      Whatsapp () {
        window.open('https://api.whatsapp.com/send?phone=6281293219318&text=Assalamualaikum%20wr%20wb%2C%20saya%20ingin%20register%20klaim%20')
      }
    }
  }
</script>
